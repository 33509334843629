<template>
  <!--begin::Help-->
  <div class="row gy-5 g-xl-8">
    <HelpIndex widget-classes="card-xxl-stretch mb-5 mb-xl-8"></HelpIndex>
  </div>
  <!--end::Help-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import HelpIndex from "@/components/help/index.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "VenuesView",
  components: {
    HelpIndex
  },
  mounted() {
    setCurrentPageTitle("Help");
  }
});
</script>
