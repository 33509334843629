<template>
  <div class="col-12 col-md-6 col-lg-12 col-xl-6 mb-7">
    <div class="card p-10 h-100">
      <div class="d-flex flex-stack mb-7">
        <div class="card-title align-items-start mt-3 w-100">
          <div class="d-flex justify-content-between w-100 align-items-center">
            <h1 class="fw-bolder ms-0 text-dark break-word-all mw-85">{{ subject.name }}</h1>
            <div>
              <div data-bs-target="tooltip"
                    style="cursor:pointer;"
                    title="Click to add new article"
                    class="svg-icon-primary svg-icon svg-icon-2x"
                    v-if="$can('article_create', 'all')"
                    @click="handleCreateNewArticle(subject)"
                >
                <PlusSign></PlusSign>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="article in subject.articles" :key="article.id">
        <div class="d-flex justify-content-between align-items-center py-3">
          <div
            class="d-flex align-items-center collapsible collapsed toggle mb-0"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse_menu_' + article.id"
          >
            <div class="ms-n1 me-5">
              <IconDropdownClosed class="toggle-off svg-icon-2" />
              <IconDropdownToggled class="toggle-on svg-icon-primary svg-icon-2"/>
            </div>
            <div class="d-flex flex-fill align-items-center justify-content-between">
              <h3 class="text-gray-800 fw-bold cursor-pointer me-3 mb-0 break-word-all">
                {{ article.title }}
              </h3>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <a
                v-if="$can('article_edit', 'all')"
                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                @click="handleEditArticle(article.id)"
            >
              <ActionButton
                  :dynamicClass="'svg-icon-light-primary'"
                  :svgSize="'svg-icon-3'"
              ></ActionButton>
            </a>
              <delete-shift-button
                  @click="handleDeleteArticleModal(article)"
                  v-if="$can('article_delete', 'all')"
              ></delete-shift-button>
          </div>
        </div>
        <div :id="'collapse_menu_' + article.id" class="collapse fs-6 ms-10">
          <div class="mb-4">
            <span class="text-muted fw-bold fs-5">{{ subject.description }}</span>
            <span
              @click="$emit('OpenArticle', article)"
              class="fs-5 ms-5 link-primary fw-bold cursor-pointer break-word-all"
              >Read more...</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import {useRouter} from "vue-router";
import PlusSign from "@/components/Icons/PlusSign";
import ActionButton from "@/components/Icons/ActionButton";
import DeleteShiftButton from "@/components/jobs/Widgets/DeleteShiftButton";
import IconDropdownToggled from "@/components/Icons/IconDropdownToggled";
import IconDropdownClosed from "@/components/Icons/IconDropdownClosed";

export default defineComponent({
  name: "helpCard",
  components: {
    ActionButton,
    IconDropdownToggled,
    IconDropdownClosed,
    PlusSign,
    DeleteShiftButton,
  },
  props: {
    subject: Object
  },
  setup(props, {emit}) {
    const router = useRouter();
    const handleEditArticle = (articleId) => {
      router.push(`/help/edit/${articleId}`);
    }
    const handleDeleteArticleModal = (article) => {
      emit("deleteArticle", article);
    }
    const handleCreateNewArticle = (category) => {
      router.push(`/help/create/${category.id}`);
    }
    return{
      handleCreateNewArticle,
      handleDeleteArticleModal,
      handleEditArticle
    }
  }
});
</script>
<style>
.cursor-pointer{
  cursor: pointer !important;
}
.break-word-all{
  word-break: break-all;
}
.mw-85{
  max-width: 85%;
}
</style>