<template>
  <DeleteModal
      :key="modalKey"
      :currently-selected-entry="Object.keys(selectedArticle).length > 0 ? selectedArticle.title : ''"
      @delete="handleDeleteArticle"
  ></DeleteModal>
  <LoadingSpinner v-if="loading"></LoadingSpinner>
  <div class="col-12 px-1 p-0" v-else>
     <div class="card mb-5 mb-xl-8">
        <div class="card-toolbar mt-5 mb-5" v-if="isCurrentUserAdmin">
           <div
            class="card-rounded bg-light d-flex ms-4 mx-4 p-5 justify-content-center"
           >
            <ul class="nav d-flex border-transparent fw-bolder flex-row">
              <li class=" my-1" v-for="role in getData" :key="role.id">
              <button
                :class="
                  currentlySelectedRole == role.id
                    ? 'btnwhite text-primary'
                    : 'btn-light'
                "
                @click="selectRole(role.id)"
                class="btn fw-boldest fs-8 nav-link px-3 px-lg-8 mx-1 text-uppercase"
              >
                {{ role.title }}
              </button>
            </li>
            </ul>
          </div>
        </div>
     </div>
  </div>
  <div class="col-12 p-0 mt-0 px-1">
     <div class="row p-0" v-if="!loading">
        <HelpArticle
          v-if="isSingleArticleSelected"
          :article="selectedArticle"
          @closeArticle="closeArticle"
        ></HelpArticle>
        <HelpCard
          v-else
          v-for="subject in getArticles"
          :subject="subject"
          :key="subject.id"
          @OpenArticle="showSingleArticle"
          @deleteArticle="handleDeleteArticleModal"
        ></HelpCard>
     </div>
  </div>
</template>

<script lang="js">
import { defineComponent, nextTick } from "vue";
import { mapActions, mapGetters } from "vuex";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import HelpArticle from "@/components/help/helpArticle.vue";
import HelpCard from "@/components/help/helpCard.vue";
import DeleteModal from "@/components/general/DeleteModal";
import {Modal} from "bootstrap";

export default defineComponent({
  name: "HelpIndexOverview",
  components: {
    DeleteModal,
    LoadingSpinner,
    HelpArticle,
    HelpCard
  },
  data() {
    return {
      currentlySelectedRole: "",
      isSingleArticleSelected: false,
      selectedArticle: {},
      modalKey: 0
    };
  },
  mounted() {
    this.loadData();
  },
  unmounted() {
    this.resetState();
  },
  methods: {
    ...mapActions("HelpModule", ["fetchArticles", "resetState", "destroyArticle"]),
    ...mapActions("RolesModule", ["fetchData"]),
    loadData() {
      this.fetchData();
      this.fetchArticles();
    },
    selectRole(selectedRole) {
      this.currentlySelectedRole = selectedRole;
      this.fetchArticles(this.currentlySelectedRole);
    },
    showSingleArticle(article) {
      this.isSingleArticleSelected = true;
      this.selectedArticle = article;
    },
    closeArticle() {
      this.isSingleArticleSelected = false;
      this.selectedArticle = {};
    },
    handleDeleteArticle() {
      this.destroyArticle(this.selectedArticle.id)
          .then(() => this.loadData());
    },
    handleDeleteArticleModal(article) {
      this.selectedArticle = article;
      this.modalKey++;
      nextTick().then(() => {
        const modal = new Modal(document.getElementById("deleteModal"));
        modal.show();
      })
    }
  },
  computed: {
    ...mapGetters("RolesModule", ["getData", "loading"]),
    ...mapGetters("HelpModule", ["getArticles"]),
    ...mapGetters({ currentUser: "currentUser" }),
    isCurrentUserAdmin() {
      if (this.$can("help_category_edit" , "all")) return true;
      else return false;
    }
  },
});
</script>

<style scoped>
.btnwhite {
  background-color: white;
}
</style>
